// Import everything from autoload folder
import './autoload/_bootstrap.js'; import './autoload/ada-compliance.js'; // eslint-disable-line

// Import local dependencies
import './plugins/lazyload';
import './plugins/modernizr.min';
//import './plugins/google-map';
import './plugins/woocommerce';
import './plugins/notification';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
import { Fancybox } from '@fancyapps/ui';
// import '@fancyapps/fancybox/dist/jquery.fancybox.min';
// import { jarallax, jarallaxElement } from 'jarallax';
// import ScrollOut from 'scroll-out';
/* global ajax_object */

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  /**
   * Add fancybox to images
   */
  $('.gallery-item')
    .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
    .attr('rel', 'gallery')
    .attr('data-fancybox', 'gallery');

  Fancybox.bind('[data-fancybox]');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });

  /**
   * Detect element appearance in viewport
   */
  // ScrollOut({
  //   offset: function() {
  //     return window.innerHeight - 200;
  //   },
  //   once: true,
  //   onShown: function(element) {
  //     if ($(element).is('.ease-order')) {
  //       $(element)
  //         .find('.ease-order__item')
  //         .each(function(i) {
  //           let $this = $(this);
  //           $(this).attr('data-scroll', '');
  //           window.setTimeout(function() {
  //             $this.attr('data-scroll', 'in');
  //           }, 300 * i);
  //         });
  //     }
  //   },
  // });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Override bootstrap submenu toggle
   */
  $('.navbar .menu-item-has-children').hover(
    function () {
      if ($(window).width() > 1199) {
        $(this).find('.dropdown-menu').addClass('show');
      }
    },
    function () {
      if ($(window).width() > 1199) {
        $(this).find('.dropdown-menu').removeClass('show');
      }
    }
  );

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    // jQuery code goes here
  });

  // Anchor click scrolling
  $(document).on('click', 'a[href^="#"]', function (e) {
    if (
      $(this).attr('href') !== '#' &&
      !$(this).hasClass('fancybox') &&
      $(this).attr('role') !== 'tab'
    ) {
      e.preventDefault();
      let $this = $(this);
      if ($($this.attr('href')).length) {
        $('html, body').animate(
          {
            scrollTop: $($this.attr('href')).offset().top - 90 + 'px',
          },
          750,
          'swing'
        );
      }
    }
  });

  // On load *only* if we have anchor on the url
  if (window.location.hash) {
    // smooth scroll to the anchor id
    $('html, body').animate(
      {
        scrollTop: $(window.location.hash).offset().top - 90 + 'px',
      },
      750,
      'swing'
    );
  }

  // Run functions
  panelInit();
  resizeVideo();
  sliderInit();
  ajaxSearch();
  ywqaAccordion();
}); // end og Document Ready

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here
  sliderInit();
  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */
let lastScrollTop = 0;
$(window).on('scroll', function () {
  // jQuery code goes here
  let st = $(this).scrollTop();
  $('body')
    .toggleClass('scrolling-top', st < lastScrollTop)
    .toggleClass('scrolling-bottom', st > lastScrollTop)
    .toggleClass('scrolling', st !== 0);
  if (st === 0) $('body').removeClass('scrolling-top scrolling-bottom');
  lastScrollTop = st;
});

/* -------------   Functions --------------------- */
/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

// Slick slider options
function sliderInit() {
  // Usps top
  $(document)
    .find('.js-usps:not(.slick-initialized)')
    .slick({
      dots: false,
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      swipeToSlide: true,
      speed: 1000,
      autoplay: false,
      autoplaySpeed: 3000,
      //variableWidth: true,
      //centerMode: true,
      centerPadding: 0,
      mobileFirst: true,
      responsive: [
        {
          breakpoint: 1520,
          settings: 'unslick',
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 2,
          },
        },
      ],
    });

  // Usps alt
  $(document)
    .find('.js-usps-alt:not(.slick-initialized)')
    .slick({
      dots: false,
      arrows: false,
      slidesToShow: 3,
      slidesToScroll: 1,
      swipeToSlide: true,
      speed: 1000,
      autoplay: false,
      autoplaySpeed: 3000,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    });

  // Advantages (Steps)
  $(document)
    .find('.js-steps-slider:not(.slick-initialized)')
    .slick({
      dots: false,
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      swipeToSlide: true,
      speed: 1000,
      autoplay: false,
      adaptiveHeight: true,
      mobileFirst: true,
      responsive: [
        {
          breakpoint: 1600,
          settings: 'unslick',
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
          },
        },
      ],
    });

  // Cat slider
  $(document)
    .find('.js-slider:not(.slick-initialized)')
    .each(function () {
      let $this = $(this);
      let $section = $this.closest('section');
      let $dots = $section.find('.js-slider-dots');
      let $rows = $this.data('rows') ?? 1;
      let $args = {
        dots: false,
        arrows: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        speed: 1000,
        autoplay: false,
        infinite: false,
        prevArrow: $section.find('.slick-control-prev'),
        nextArrow: $section.find('.slick-control-next'),
        rows: $rows,
        responsive: [
          {
            breakpoint: 1200,
            slidesToShow: 2.5,
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 1.5,
            },
          },
        ],
      };

      if ($dots.length) {
        $args.asNavFor = $dots;
      }

      $this.slick($args);

      // Connected slider navigation dots
      if ($dots.length) {
        $dots.slick({
          dots: false,
          arrows: false,
          slidesToShow: 4,
          slidesToScroll: 1,
          speed: 1000,
          autoplay: false,
          infinite: true,
          asNavFor: $this,
          focusOnSelect: true,
        });
      }
    });

  // Reviews
  $(document)
    .find('.js-slider-center:not(.slick-initialized)')
    .each(function () {
      let $this = $(this);
      let $section = $this.closest('section');
      let $dots = $section.find('.js-slider-dots');

      $this.on('init beforeChange', function () {
        $('.matchHeight').matchHeight();
      });
      $this.slick({
        dots: false,
        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: true,
        speed: 1000,
        autoplay: false,
        centerMode: true,
        infinite: true,
        centerPadding: '30%',
        adaptiveHeight: true,
        asNavFor: $dots,
        prevArrow: $section.find('.slick-control-prev'),
        nextArrow: $section.find('.slick-control-next'),
        responsive: [
          {
            breakpoint: 1199,
            settings: {
              slidesToShow: 1,
              centerPadding: '20%',
            },
          },
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 1,
              centerPadding: 0,
            },
          },
        ],
      });

      // Connected slider navigation dots
      $dots.slick({
        dots: false,
        arrows: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        speed: 1000,
        autoplay: false,
        infinite: true,
        asNavFor: $this,
        focusOnSelect: true,
      });
    });
}

// Side panel
function panelInit() {
  $(document).on('click', '.js-panel-toggle', function (e) {
    e.preventDefault();
    e.stopPropagation();

    let panel_id = $(this).data('panel');
    let $panel = $(panel_id);
    if ($panel.length) {
      $('body').toggleClass('has-overlay');
      $panel.toggleClass('is-visible');
    }
  });

  $(document).on('click', 'body.has-overlay', function (e) {
    let $target = $(e.target);
    if (!$target.closest('.side-panel').length) {
      $('body').removeClass('has-overlay');
      $('.side-panel').removeClass('is-visible');
    }
  });
}

// Ajax Search
function ajaxSearch() {
  let searchRequestTimer;
  $(document).on('input submit', '.js-search', function (e) {
    e.preventDefault();
    e.stopPropagation();
    clearTimeout(searchRequestTimer);
    let $searchPopup = $(document).find('#search-popup');
    let $searchResults = $searchPopup.find('.js-search-results');
    let $searchOverlay = $searchPopup.find('.ajax-overlay');
    let searchString = $(this).find('input[type="search"]').val();
    let searchTypes = {};

    if ($searchPopup.length && $searchResults.length) {
      $searchOverlay.addClass('ajax-overlay--active');

      // Get search containers type/name
      $searchResults.each(function (i, el) {
        if ($(el).data('name') && $(el).data('type')) {
          searchTypes[$(el).data('name')] = $(el).data('type');
        }
      });

      // Show/hide
      if (searchString.length > 2) {
        $searchPopup.fadeIn();
        $('body').addClass('lock');
      } else {
        $searchPopup.fadeOut();
        $('body').removeClass('lock');
      }

      if (searchString.length > 2) {
        searchRequestTimer = setTimeout(function () {
          $.post(ajax_object.ajax_url, {
            action: 'live_search',
            to_search: searchString,
            types: JSON.stringify(searchTypes),
          }).done(function (response) {
            $.each(response.results, function (name, html) {
              $searchResults.filter('[data-name="' + name + '"]').html(html);
            });
            $searchOverlay.removeClass('ajax-overlay--active');
          });
        }, 500);
      }
    }
  });
}

// YITH WooCommerce Question and Answers
function ywqaAccordion() {
  // Open/close answers
  $(document).on('click', '.question-content', function () {
    let $this = $(this),
      $list = $this.closest('.ywqa-items-list'),
      $listItems = $list.find('li.question-container'),
      $thisContent = $this.siblings('.answer-content');

    $listItems.each(function (i, el) {
      $(el).find('.answer-content').not($thisContent).slideUp();
    });

    $thisContent.slideToggle(); // Open/close current
    $this.toggleClass('open');
  });

  // Open/close asking form
  let $ywqaToggle = $(document).find('#ywqa_form_toggle');
  if ($ywqaToggle.length === 0) $(document).find('#ask_question').show();

  $(document).on('click', '#ywqa_form_toggle', function (e) {
    e.preventDefault();
    $(this).toggleClass('open');
    $(document).find('#ask_question').slideToggle();
  });
}
