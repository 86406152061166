// Woocommerce scripts
/* global ajax_object */
/* global FWP */

$(() => {
  /*const $body = $('body');
const isWoocommercePage = $body.hasClass('woocommerce-page');
const isCheckoutPage = $body.hasClass('woocommerce-checkout');
const isCartPage = $body.hasClass('woocommerce-cart');*/
  const $woocommerce = $('.woocommerce');

  // -/+ buttons for a product amount input.
  $woocommerce.on(
    'click',
    '.js-add-to-cart-plus, .js-add-to-cart-minus',
    (event) => {
      let $this = $(event.currentTarget);

      // Get current quantity values.
      let $qty = $this
        .closest('.js-cart-quantity')
        .find('input[type="number"].qty');
      let $miniCart = $qty.closest('.js-mini-cart-inner');

      let val = parseFloat($qty.val());
      let max = $qty.attr('max') ? parseFloat($qty.attr('max')) : 9999;
      let min = $qty.attr('min') ? parseFloat($qty.attr('min')) : 0;
      let step = $qty.attr('step') ? parseFloat($qty.attr('step')) : 1;

      // Change the value if plus or minus.
      if ($this.is('.js-add-to-cart-plus')) {
        if (max && max <= val) {
          $qty.val(max);
        } else {
          $qty.val(val + step);
        }
      } else {
        if (min && min >= val) {
          $qty.val(min);
        } else if (val > 0.5) {
          $qty.val(val - step);
        }
      }

      // Dynamic pricing selector
      checkPricingRules(parseInt($qty.val()));

      $qty.trigger('input').trigger('change');

      if ($miniCart.length && typeof ajax_object !== 'undefined') {
        $miniCart.addClass('ajax-overlay ajax-overlay--active');
        $.post({
          url: ajax_object.ajax_url,
          data: {
            action: 'product_change_qty',
            nonce: ajax_object.nonce,
            cartItemKey: $qty.attr('name'),
            newQty: $qty.val(),
          },
          success: () => {
            $(document.body).trigger('wc_fragment_refresh');
            $miniCart.removeClass('ajax-overlay--active');
          },
          error: (error) => {
            console.log('$.ajax.error()');
            console.log(error);
            $miniCart.removeClass('ajax-overlay--active');
          },
        });
      }

      $(document).find('button[name="update_cart"]').trigger('click');
    }
  );

  // Check manual product qty change
  $woocommerce.on('change', 'input[name="quantity"].qty', (event) => {
    let $this = $(event.currentTarget);

    // Dynamic pricing selector
    checkPricingRules(parseInt($this.val()));
  });

  // Cart page qty manual change
  $woocommerce.on('change', 'input[name^="cart"].qty', () => {
    $(document).find('button[name="update_cart"]').trigger('click');
  });

  // Set min value to the quantity as the default one.
  $(() => {
    let $qty = !$('body').hasClass('woocommerce-cart')
      ? $('form.cart').find('input[type="number"].qty')
      : null;
    if ($qty) {
      let min = $qty && $qty.attr('min') ? parseFloat($qty.attr('min')) : 1;
      $qty.val(min);
    }
  });

  // FacetWP Min/Max price range
  $(document).ready(function () {
    facetRangeApply();
  });
  $(document).on('facetwp-loaded', function () {
    facetRangeApply();

    // Hide empty facets
    $(document)
      .find('.filter_item')
      .each(function () {
        $(this).toggleClass(
          'd-none',
          $(this).find('.facetwp-facet').is(':empty')
        );
      });

    // Scroll top after filtering
    if (FWP.loaded) {
      $('html, body').animate(
        {
          scrollTop: $('.facetwp-template').offset().top - 150,
        },
        500
      );
    }
  });
  $(document).ready(function () {
    removeResponsiveTableClass();
  });
  $('body').on(
    'updated_wc_div update_checkout wc_fragment_refresh wc_fragments_refreshed',
    function () {
      removeResponsiveTableClass();
    }
  );

  // Main document ready
  $(document).ready(function () {
    // Free gift product functionality
    $('#cart-free-product').on('change', function () {
      let checkbox = $(this),
        id = checkbox.data('id');

      let data = {
        action: 'wc_add_to_cart',
        product_id: id,
      };
      console.log(data);
      $.ajax({
        // you can also use $.post here
        url: ajax_object.ajax_url, // AJAX handler
        data: data,
        type: 'POST',
        beforeSend: function () {
          // button.text('Loading...'); // change the button text, you can also add a preloader image
        },
        success: function () {
          $(document.body).trigger('wc_fragment_refresh');
          $(document.body).trigger('added_to_cart');
          setTimeout(function () {
            checkbox.prop('disabled', false);
          }, 200);
        },
        error: function (xhr, ajaxOptions, thrownError) {
          // For debugging, console log any errors. In production, do something different.
          console.log(xhr.status);
          console.log(thrownError);
        },
      });
    });

    // Coupon hide/show mobile
    $('.js-discount-toggle').on('click', function () {
      if ($(window).width() < 992) {
        $(this).siblings('#coupon_code, button').slideToggle();
      }
    });

    // Dynamic pricing product discount (Change price)
    $(document).on('quick_view_pro:open', function () {
      renderDynamicPrices();
    });

    if ($('body').hasClass('single-product')) {
      renderDynamicPrices();
    }

    /*if (
        customVariablePricesContainer.length &&
        customVariablePricesContainer.is(':space')
      ) {
        $('form.variations_form').addClass('show-price');
        $('.woocommerce-variation-price').show();
      }*/

    $(document).on(
      'show_variation',
      'form.variations_form',
      function (event, variation) {
        let $discount_options = $(document).find('.website_pricing_item');
        let variation_id = variation.variation_id.toString();

        if ($discount_options.length && variation.variation_id) {
          $discount_options.each(function (i, el) {
            let variations = $(el).data('variations')
              ? $(el).data('variations').toString()
              : '';
            // Hide/show items
            $(el).toggleClass(
              'd-block',
              variations.indexOf(variation_id) !== -1
            );
          });
        }
      }
    );

    // Go to specific tab
    if ($(document.location.hash).length && $('#checkoutStepsContent').length) {
      $(document.location.hash).tab('show');
    }

    // Product gallery destroy slick slider
    $('.iconic-woothumbs-images').on('init', function (event, slick) {
      setTimeout(function () {
        slick.unslick();
      }, 300);
    });

    if ($('body').hasClass('woocommerce-checkout')) {
      $('#checkoutSteps')
        .on('click', 'a.nav-tab-link', function (event) {
          event.preventDefault();
          $(this).tab('show');
        })
        .on('click', 'a.disabled', function (event) {
          event.preventDefault();
        });

      $('#checkoutSteps a.nav-tab-link').on('shown.bs.tab', function (event) {
        $(event.target).removeClass('passed');

        // set 'passed' except #tab-payment - last tab
        if (event.relatedTarget.id !== 'tab-payment') {
          $(event.relatedTarget).addClass('passed');
        }
      });
    }

    // Checkout process clone button
    $(document).on('click', '.js-checkout-toggle', function (e) {
      let targetTabId = $(this).data('target');

      if ($(targetTabId).length) {
        e.preventDefault();
        $(targetTabId).tab('show');
        $(this).blur();
      }
    });

    // Single product add to cart
    $('.single_add_to_cart_button').on('click', function (e) {
      e.preventDefault();
      let $this = $(this),
        $form = $this.closest('form.cart'),
        id = $this.val(),
        product_qty = $form.find('input[name=quantity]').val() || 1,
        product_id = $form.find('input[name=product_id]').val() || id,
        variation_id = $form.find('input[name=variation_id]').val() || 0;
      let data = {
        action: 'website_wc_ajax_add_to_cart',
        product_id: product_id,
        product_sku: '',
        quantity: product_qty,
        variation_id: variation_id,
      };
      $.ajax({
        type: 'post',
        url: ajax_object.ajax_url,
        data: data,
        beforeSend: function () {
          $this.removeClass('added').addClass('loading');
        },
        complete: function () {
          $this.addClass('added').removeClass('loading');
          setTimeout(function () {
            $this.removeClass('added');
          }, 1000);
        },
        success: function (response) {
          if (response.error && response.product_url) {
            window.location = response.product_url;
            return '';
          } else {
            $('header .js-panel-toggle').trigger('click');
            $(document.body).trigger('added_to_cart', [
              response.fragments,
              response.cart_hash,
              $this,
            ]);
          }
        },
      });
    });

    // Quantity selector (Dynamic pricing)
    $(document).on('click', '.js-set-quantity', function (e) {
      e.preventDefault();
      e.stopPropagation();

      // Set active
      $(document).find('.js-set-quantity').removeClass('active');
      $(this).addClass('active');

      // Set quantity
      if ($(this).data('quantity')) {
        $(document)
          .find('input[name="quantity"]')
          .val($(this).data('quantity'))
          .trigger('change');
      }
    });

    // Plant finder
    $(document).on('click', '.js-find-plants', function () {
      let $filters = $(document).find('.js-product-terms');
      let $filters_values = {};
      let $container = $(document).find('.js-find-plants-content');

      $container.addClass('ajax-overlay--active');
      $filters.each(function () {
        if ($(this).val()) {
          $filters_values[$(this).attr('id')] = parseInt($(this).val());
        }
      });

      // AJAX Search
      $.post(ajax_object.ajax_url, {
        action: 'plants_finder',
        /* value: $form.find('input').val(),*/
        plant_filters: JSON.stringify($filters_values),
      }).done(function (response) {
        $container.html(response.html).removeClass('ajax-overlay--active');
      });
    });
  }); // end of Document ready

  /**
   * Show/hide the billing company field by the custom added checkbox.
   *
   * [1] - Important: The 'null' is added to the hidden billing Company field for to pass a required condition.
   *
   * @see The 'billing_company' & 'shipping_company' fields in the 'woocommerce_checkout_fields' hook in the woocommerce-checkout.php.
   */
  const $formCheckout = $('form.checkout');
  const $vatField = $('#woocommerce_eu_vat_number_field');
  const $companyFields = $('#billing_company_field, #shipping_company_field');
  if ($formCheckout.length && $companyFields.length) {
    // Init state.
    if ($vatField.length) {
      $vatField.addClass('d-none');
      $companyFields.addClass('d-none');
    }

    // Show by the checkbox.
    $formCheckout.on('change', '#company-order-checkbox', (event) => {
      const isChecked = $(event.currentTarget).prop('checked');
      const $fields = $().add($companyFields).add($vatField);

      if ($fields.length) {
        $fields.toggleClass('d-none', !isChecked);
        $companyFields.find('.input-text').val(isChecked ? '' : 'null'); // [1]
      }
    });
  }
});

function checkPricingRules($qty) {
  let pricingRules = $(document).find('.js-set-quantity');
  pricingRules.removeClass('active');

  if (pricingRules.length && $qty) {
    pricingRules.each(function (index, el) {
      if (
        $qty === $(el).data('quantity') ||
        ($(el).data('to') &&
          $qty <= $(el).data('to') &&
          $qty > $(el).data('quantity')) ||
        ($(el).data('to') === '' && $qty > $(el).data('quantity'))
      ) {
        $(el).addClass('active');
      }
    });
  }
}

function facetRangeApply($container) {
  let $facetContainer = $container ?? $('.facetwp-type-number_range');
  if ($facetContainer.length) {
    let $facetMin = $facetContainer.find('input.facetwp-number-min');
    let $facetMax = $facetContainer.find('input.facetwp-number-max');
    let $websiteMin = $facetContainer.find('[name="web_select_min"]');
    let $websiteMax = $facetContainer.find('[name="web_select_max"]');

    if ($facetMin.length && $facetMin.val()) {
      $websiteMin.val($facetMin.val());
    }
    if ($facetMax.length && $facetMax.val()) {
      $websiteMax.val($facetMax.val());
    }

    $('.js-facet-range').on('change', 'select', function () {
      let $this = $(this);

      if ($this.attr('name') === 'web_select_min') {
        $facetMin.val($this.val()).trigger('change');
        setOptionDisabled($websiteMax, $this.val());
      } else if ($this.attr('name') === 'web_select_max') {
        $facetMax.val($this.val()).trigger('change');
        setOptionDisabled($websiteMin, $this.val(), 'max');
      }

      $this.blur();
    });
  }
}

function setOptionDisabled($select, $value, $type = 'min') {
  if (typeof $select === undefined || !$select.length) return false;
  if (typeof $value === undefined) return false;

  $select.find('option').removeAttr('disabled');
  $select.find('option').each(function () {
    if (
      ($type === 'min' && parseInt($value) >= parseInt($(this).val())) ||
      ($type === 'max' && parseInt($value) <= parseInt($(this).val()))
    ) {
      $(this).attr('disabled', 'disabled');
    }
  });
}

function removeResponsiveTableClass() {
  $(document).find('table.shop_table').removeClass('shop_table_responsive');
}

// Dynamic pricing on Single page
/**
 * @param {number,string}  currentValue The number or string
 * @param {array} prices The array
 * @param {array} ranges The array
 */
function showCurrentPrice(currentValue, prices, ranges) {
  if (!Array.isArray(prices) && !Array.isArray(ranges)) {
    return;
  }
  $(prices).each(function () {
    $(this).not(':first-child').hide();
    $(this).first().removeClass('line-through');
  });
  $(ranges).each(function (index, value) {
    if (
      (value.to &&
        value.from &&
        +currentValue >= +value.from &&
        +currentValue <= +value.to) ||
      (!value.to && value.from && +currentValue >= +value.from)
    ) {
      $(prices[+index + 1]).show();
      $(prices[0]).addClass('line-through');
    }
  });
}

function getRanges(fromStr, toStr, prices) {
  if (typeof fromStr !== 'string' || typeof toStr !== 'string') return false;
  let from = fromStr.split(', ');
  let to = toStr.split(', ');
  let ranges = [];
  if (from && to && prices && prices.length > 0) {
    for (let i = 0; i < prices.length - 1; i++) {
      ranges.push({ from: from[i], to: to[i] });
    }
  }
  return ranges;
}

function displayDynamicPrices(container, quantity) {
  if (container.length) {
    let prices = container.find('.amount');
    let from = container.data('from').toString();
    let to = container.data('to').toString();
    let ranges = getRanges(from, to, prices);
    let currentValue = quantity.val();
    showCurrentPrice(currentValue, prices, ranges);
    quantity.on('change', function () {
      let currentValue = $(this).val();
      showCurrentPrice(currentValue, prices, ranges);
    });
  }
}

function renderDynamicPrices() {
  let simplePricesContainer = $(document).find(
    '.entry-summary p.price.simple-product-prices'
  );
  let quantity = $(document).find('.cart_quantity input[name="quantity"]');
  displayDynamicPrices(simplePricesContainer, quantity);
  let customVariablePricesContainer = $(document).find(
    '.entry-summary p.price.variable-product-prices'
  );
  if (customVariablePricesContainer.length) {
    let form = $(document).find('form.variations_form');
    $(document).on('change', 'form.variations_form', function () {
      let radio = form.find('td.value input[type="radio"]:checked');
      let pricesDiv = $(document).find('.woocommerce-variation-custom-price');
      let customVariablePrices = $(customVariablePricesContainer).find(
        '.prices-item'
      );
      customVariablePrices.each(function () {
        if ($(this).data('slug') && $(this).data('slug') === radio.val()) {
          pricesDiv.html($(this).clone());
        }
      });
      let variablePricesContainer = pricesDiv.find('.prices-item');
      let quantityVariable = form.find('input[name="quantity"]');
      displayDynamicPrices(variablePricesContainer, quantityVariable);
    });
    form.find('.reset_variations').click(function () {
      $('.woocommerce-variation-custom-price').hide();
    });
    form.find('td.value input[type="radio"]').click(function () {
      $('.woocommerce-variation-custom-price').show();
    });
  }
}
