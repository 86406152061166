/**
 * The Notification Popup.
 *
 * It uses js-cookie as Cookies:
 * @link https://github.com/js-cookie/js-cookie
 * @link https://www.npmjs.com/package/js-cookie
 * > npm install js-cookie --save
 */

import Cookies from 'js-cookie';

$(() => {
  // Find the notification modal
  let $modal = $('#website-modal-notification');
  if ($modal.length) {
    let cookieName = $modal.attr('data-cookie-name');
    let timeout = parseFloat($modal.attr('data-timeout'));

    // Get the notification cookie
    let notificationWasShowed = cookieName ? Cookies.get(cookieName) : '';

    // If the notification was not showed
    if (notificationWasShowed !== 'true') {
      // Show the modal
      $modal.modal();

      // Update the cookie
      if (cookieName && timeout) {
        Cookies.set(cookieName, 'true', { expires: timeout, sameSite: 'lax' });
      } else {
        Cookies.remove(cookieName);
      }
    }
  }
});
